
  import Vue from 'vue'

  export default Vue.extend({
    // @ts-ignore
    functional: true,
    // Props are optional
    props: {
      breadcrumbs: {
        type: Array,
        required: true,
      },
      hidden: {
        type: Boolean,
        default: false,
      },
    },
    // To compensate for the lack of an instance,
    // we are now provided a 2nd context argument.
    // @ts-ignore
    render(createElement, context) {
      const createIconCrumb = function (icon: string, link?: string) {
        return createElement('span', {
          class: 'crumb one-breadcrumbs__item',
        }, [
          createElement('i', {
            class: `one-breadcrumbs__icon ${icon}`,
            on: {
              // @ts-ignore
              click: () => link ? context.parent.$router.push(link) : ({}),
            },
          }),
        ])
      }
      const createTextCrumb = function (name: string, link?: string) {
        if (link) {
          return createElement('nuxt-link', {
            class: 'crumb one-breadcrumbs__item',
            domProps: {
              textContent: name,
            },
            props: {
              to: link,
            },
          })
        }
        return createElement('span', {
          class: 'crumb one-breadcrumbs__item',
          domProps: {
            textContent: name,
          },
        })
      }
      const separator = () => createElement('span', {
        class: 'crumb one-breadcrumbs__separator px-1',
        domProps: {
          textContent: '/',
        },
      })
      if (!context.props.hidden) {
        const crumbs: Array<any> = []
        crumbs.push(createIconCrumb('icon-home', '/'))
        context.props.breadcrumbs.forEach((crumb: any) => {
          crumbs.push(separator())
          if (typeof crumb === 'string' || !crumb.link) {
            crumbs.push(createTextCrumb(crumb.name || crumb))
          } else {
            crumbs.push(createTextCrumb(crumb.name, crumb.link))
          }
        })
        /**
         * We reverse the array because we render list of crumbs 'direction: rtl' (see styles)
         * This and hidden scrollbar (under .one-breadcrumbs which has fixed height) gives scrollable
         * breadcrumbs than on smaller screens always display the last item.
         */
        crumbs.reverse()
        return createElement('div', {
          class: 'one-breadcrumbs py-1',
        }, [createElement('div', {
          class: 'one-breadcrumbs__col',
        }, crumbs)])
      }
      return null
    },
  })
